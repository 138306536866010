@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

//variables
$lip_grey:#282828;
$lip_darker_grey:#161616;
$lip_light_grey:#f3f3f3;
$lip_text_grey:#707070;

//global_styles
a{
  color:white;
  cursor:pointer;
}

a:visited{
  color: white;
}
a:hover{
 color: #d8b77d;
}


body{
background-image:url("../img/bg.svg");
background-attachment:fixed;
width: 100%;
height: 100%;
margin: 0px;
padding: 0px;
overflow-x: hidden;
}

.row .row{
  margin-right: 0;
  margin-left: 0;
}

  .title{
    margin-top:50px;
    margin-bottom:50px;
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
    border-color: white transparent transparent;
  }

  .d-d a{
    color: $lip_darker_grey;
  }

//class_styles
.header__dark{
  height:80px;
  background-color:$lip_darker_grey;
  margin-top:100px;
  padding:25px 50px;

  .menu-c{
    text-transform:uppercase;
    font-size:12px;
    font-weight:800;
  }
}

.open-menu{
  color:white;
}

.logo{
margin-top:-5px;
   img{
    width:70%;
  }

}

.mobile-menu{
  position: fixed;
  background-color:$lip_darker_grey;
  height: 100%;
  width:100%;
  z-index: 9000;
  display: none;


  .m-m{
   margin-top: 150px;
   }

     ul li{
      list-style: none;
      margin-bottom: 50px;
    }

    .close{
      color:white;
      float:right;
      margin-right:50px;
      margin-top:50px;
    }

    .social li{
      display: inline;
      color:white;
      margin-right: 20px;
      font-size: 20px;
    }
  }


.text-block_dark{
  position: absolute;
  width:40%;
  right:130px;
  top:45%;
  color:white;
   opacity: 0.6;


  h1 {
      font-size:60px;
      font-family:'Playfair Display', serif;
      font-weight: 700;
      line-height:1;
      margin-bottom: 20px;
      line-height: 60px;
    }


    .buttons{
      margin-top:30px;

      .icon{
        margin-left: 10px;
      }

      .p-button{
        background-color: white;
        color: #4f345a;
        opacity: 0.9;
        padding:11px 50px;
        border-radius: 50px;
        font-size: 12px;
        margin-right:20px;
      }

      .s-button{
        padding:11px 50px;
        border-radius: 50px;
        border: 1px solid white;
        color:white;
        opacity: 0.6;
        font-size: 12px;
      }
    }
}

.text-block_light{
  position: absolute;
  width:40%;
  right:130px;
  top:30%;
  color:$lip_darker_grey;
   opacity: 0.9;


  h1 {
      font-size:60px;
      font-family:'Playfair Display', serif;
      font-weight: 700;
      line-height:1;
      margin-bottom: 20px;
      line-height: 60px;
    }


    .buttons{
      margin-top:30px;

      .icon{
        margin-left: 10px;
      }



      .p-button{
        background-color: $lip_darker_grey;
        color: white;
        opacity: 1;
        padding:11px 50px;
        border-radius: 50px;
        font-size: 12px;
        margin-right:20px;



      }

      .s-button{
        padding:11px 50px;
        border-radius: 50px;
        border: 1px solid white;
        color:white;
        opacity: 0.6;
        font-size: 12px;
      }
    }
}



.hero{
  // background-image: url("../img/hero3.jpg");
  background-position-y: 30px;
  background-color:white;
  max-height:700px;


  .row{
    margin-right:0;
    margin-left:0;

    .column{
      padding-left: 0;
      padding-right: 0;
    }

  }

}



.bar{
  height:2px;
  background-color: #d8b77d;
  margin-top: 325px;
}

.about{
  height:700px;
  background-color:$lip_darker_grey;
  color:white;


  .about--text{
//  text-align:center;
  max-width:600px;
  margin-top:180px;



    h1 {
      font-size:50px;
      font-family:'Playfair Display', serif;
      line-height:1;
      margin-bottom:40px;
      text-transform: capitalize;
      color:#d8b77d;
    }

    p{
      font-size:15px;
      text-align:justify;
      font-weight: 300;
    }
  }

}


.product__showcase{
  height:auto;
  background-color:$lip_light_grey;
   padding:100px;

     .row{
    margin-bottom:35px;
  }



    h1 {
      font-size:50px;
      font-family:'Playfair Display', serif;;
      line-height:1;
      margin-bottom:10px;
      color:$lip_text_grey;
    }

    p{
      color:$lip_text_grey;
    }

}

.btn-container{
  margin-top:50px;
}

.btn {
  background-color: white;
  color:#333;
  padding:10px 50px;
}








.hide_product--image{
  transform: translateY(200px);
 opacity: 0;
}

.show_swatch--image{
  opacity: 1 !important;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform:rotateY(180deg);
 right:60px;
 }

 .flip_product--card{
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform:rotateY(180deg);
  backface-visibility: hidden;
}

.flip_product--image{
    -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform:rotateY(180deg);
  backface-visibility: hidden;
}

.cards{

  min-height: 500px;

}


.product--card{
  min-height: 500px;
  background-color: white;
  padding:30px 50px 30px 50px;
  border-radius: 2px;
    //box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);
  z-index: 2000;
  position: relative;


  &:hover{
     box-shadow: 0 14px 28px rgba(0,0,0,0.07), 0 10px 10px rgba(0,0,0,0.05);
  }

    .swatch--image{
    position: absolute;
     transition: all 0.6s cubic-bezier(.25,.8,.25,1);
     z-index:-1;
     top:140px;
    opacity: 0;

     img{
       width:70%;
     }
    }





  .image-contain{
    height: 400px;
    overflow: hidden;
  }

  .swatch--toggle{
    a{
    color:$lip_text_grey;
    font-size: 12px;
    cursor:pointer;
    }
  }

  .product--image{
    margin-bottom: 10px;
    transition: all 0.6s cubic-bezier(.25,.8,.25,1);
    overflow: hidden;
    backface-visibility: hidden;
    background-color:white;


     img {
   margin-left:70px;
   width:30%;

 }

  }

  .product--name{
    p{
      text-transform: capitalize;
      margin-bottom: 0;
    }

  }
}

.top-photo{
  height:auto;
}

.contact{
  height:auto;
  background-color:$lip_darker_grey;
  padding:150px 100px 150px 100px;

    h1 {
      font-size:40px;
      font-family:'Playfair Display', serif;
      line-height:1;
      margin-bottom:10px;
      color:#d8b77d;
    }

    p{
      color:white;
    }

.deets{
  margin-top:70px;
}


}

.news{
height:auto;
  background-color:$lip_light_grey;
  padding:50px 100px 100px 100px;

   h1 {
      font-size:50px;
       font-family:'Playfair Display', serif;
      line-height:1;
      margin-bottom:10px;
      color:$lip_text_grey;
    }

}



.footer{
  padding:50px;
  height:100px;
  background-color:$lip_darker_grey;
  color:white;
  font-size: 10px;

ul li{
     display: inline;
    margin-right:30px;
   }

  img{
    width:60%;
  }

    .social{
    .fa{
    font-size: 20px;
  }
  }
}

.large-text{
  background-color: white;
  color: $lip_text_grey;
  padding:100px;
}

.buy{
  min-height: auto;
  overflow: hidden;
  padding:100px 0;
  background-color: white;

  .buy__details{
    padding: 0 200px;


    .item__name{
      font-size: 26px;
      color: #4c4c4c;
    }

    .buy__button{
      margin:50px 0;
    }

    .item__price{
      font-size: 18px;
      color: #4c4c4c;
    }
  }

  .image {
    width:200px;
    img{
      width:50%;
    }
  }
}





//Media Queries
@media screen and (min-width:320px) and (max-width:480px){


  body{
    background-image: none;
    background-attachment: scroll;
  }

.header__dark{
  margin-top: 0;

  .menu-c{
    display:none;
  }
}

.orbit{
  display: none;
}

.hero{
  height: 700px;
  background-image: url("../img/hero2_small.jpg");
  background-size: cover;
  background-position: center;

  .small-offset-6{
    margin:10%;
  }

  .hero--text{
  width:100%;
  margin-top: 0px;
  margin-left: 0px;
  position: absolute;
  text-align: center;
  padding: 20px;



  top:20%;
  color:$lip_text_grey;



  h1 {
      font-size:50px;
      font-family:'Playfair Display', serif;
      font-weight: 700;
      line-height:1;
      margin-bottom: 20px;
      line-height: 60px;
    }

p{
  font-size: 13px;
}

    .buttons{
      margin-top:30px;

      .icon{
        margin-left: 10px;
      }



      .p-button{
        background-color: $lip_text_grey;
        color: white;
        opacity: 1;
        padding:11px 50px;
        border-radius: 50px;
        font-size: 12px;
        margin-right:20px;



      }

      .s-button{
        padding:11px 50px;
        border-radius: 50px;
        border: 1px solid white;
        color:white;
        opacity: 0.6;
        font-size: 12px;
      }
    }
}
}
.about{
  padding:15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .about--text{
    margin-top: 70px;
    text-align: center;

    p{
      text-align: center;
    }

  }
  .bar{
    display: none;
  }
}


  .product__showcase{
    padding:20px;

    .product--card{
      margin-bottom: 30px;
    }
  }

  .contact{
  height:auto;
  background-color:$lip_darker_grey;
  padding:150px 2px 150px 20px;
  }

  .news{
    padding:15px;
  }

  .large-text{
    padding: 20px;
  }

  .footer{
    padding: 0 0 0 0;
    min-height: 210px;
    line-height: 22px;
    font-size: 12px;

    ul{
      margin-bottom: 0;
    }

    .f-options{
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .inline li{
    line-height: 35px;
    }

.social{
  // display: none;
}



    img{
      display: none;
    }

  }

  .buy{
  min-height: auto;
  overflow: hidden;
  padding:100px 0;
  background-color: white;

  .buy__details{
    padding: 0 15px;


    .item__name{
      font-size: 26px;
      color: #4c4c4c;
    }

    .buy__button{
      margin:50px 0;
    }

    .item__price{
      font-size: 18px;
      color: #4c4c4c;
    }
  }

  .image {
    width:200px;
    img{
      width:30%;
    }
  }
}
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  body{
    background-image: none;
    background-attachment: scroll;
  }

.header__dark{
  margin-top: 0;

  .menu-c{
    display:none;
  }
}

.m-m, .social{
  text-align: center;
}

.hero{
  height:448px;
}

.about{
  padding:15px;
  display: flex;
  justify-content: center;
  align-items: center;

  .about--text{

    margin-top: 70px;
    text-align: center;

    p{
      text-align: center;
    }
  }
  .bar{
    display: none;
  }
}


  .product__showcase{
    padding:150px;

    .product--card{
      margin-bottom: 30px;
      display: flex;
   flex-direction: column;
   justify-content: center;

   .product--image{
     align-self: center;
   }

   .swatch--toggle{
     font-size: 18px;
   }

    }
  }

.text-block_dark{


  h1{
    font-size: 25px;
    margin-bottom: 0;
  }

  p{
    font-size: 12px;
  }
}

  .text-block_light{
      right: 85px;

    h1{
      font-size: 25px;
      margin-bottom: 10px;
      line-height: 25px;
    }

    p{
      font-size: 12px;
    }
  }

  .contact{
    padding:150px 25px 150px 25px
  }

  .footer{
    padding: 0px;
    min-height: 150px;
    line-height: 22px;
    font-size: 12px;

    .inline{
      display: flex;
      justify-content: center;
    }

  .social{
    // display: none;
  }
}
}

@media screen and (max-width: 1024px) and (max-height: 1366px){
  body{
    background-image: none;
    background-attachment: scroll;
  }

.header__dark{
  margin-top: 0;
}
}
